import { GOOGLE_MAP_KEY } from "../constants/index";

export const getDirections = async (coords, gmapApi = window?.google?.maps) => {
    if (!gmapApi) {
        return null;
    }
    const directionsService = new gmapApi.DirectionsService();

    const request = {
        origin: coords[0],
        destination: coords[coords.length - 1],
        waypoints: coords.reduce((acc, coord, i) => {
            if (![0, coords.length - 1].includes(i)) {
                acc.push({
                    location: new gmapApi.LatLng(coord),
                    stopover: true,
                });
            }
            return acc;
        }, []),
        travelMode: gmapApi.TravelMode.DRIVING,
        unitSystem: gmapApi.UnitSystem.METRIC,
    };
    let directions = null;
    try {
        const result = await directionsService.route(request);
        if (result.status === gmapApi.DirectionsStatus.OK) {
            directions = result;
        } else {
            throw new Error(result.status);
        }
    } catch (e) {
        console.error("error", e);
    }
    return directions;
};

// TODO: load from server gmap credentials
export const getGMapOptions = async () => {
    return Promise.resolve({
        apiKey: GOOGLE_MAP_KEY,
        language: "de",
    });
};
