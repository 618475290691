import Moment from "moment";
import { extendMoment } from "moment-range";
import {
    EVENT_VIEW,
    eventCardClassNameMapping,
    eventCardClassNameMappingOptional,
} from "../constants/index";
import { getDirections } from "./google-maps-api";
import { getDateStartTime, MINUTE } from "./utility";
import { cloneDeep } from "lodash";
const moment = extendMoment(Moment);

export const getEventCoordinates = (evt) => evt?.extendedProps?.coordinates;
export const hasLatLng = (coordinates) =>
    !!(coordinates?.lat && coordinates?.lng);
export const getLatLng = ({ lat, lng }) => ({ lat, lng });
const sortEvents = (a, b) =>
    new Date(a.start).getTime() - new Date(b.start).getTime();

const HOME_START_ID = "home_start";
const HOME_END_ID = "home_end";
const DEFAULT_ACCOMMODATION_SHADOW_EVENT_DURATION_MINUTES = 30;

// TODO: refactoring

const createCalendarEvent = (event) => {
    const isAccommodation = event.provider?.includes("accommodation");
    return {
        start: event.fromDate,
        end: event.toDate,
        id: event.id,
        title: event.title,
        classNames: getEventCardColor(event),
        allDay: isAllDayEvent(event.fromDate, event.toDate),
        // FIXME: 2 price fields?
        extendedProps: {
            eventView: isAccommodation
                ? EVENT_VIEW.ACCOMMODATION
                : EVENT_VIEW.SERVICE,
            coordinates: event?.product?.address?.coordinates,
            price: event.price.amount,
            currency: event.price.currency,
            name: event.title,
            optional: event.optional,
            id: event.id,
            isAccommodation,
            city: event.product?.address?.city,
            country: event.product?.address?.country,
            reference: event.data?.roomCategory,
            price: event.offerItemPriceWithOptionsPerPerson,
            ...(isAccommodation && {
                fare: event.data?.fare,
                roomCategory: event.data?.roomCategory,
            }),
        },
    };
};

export const createDayStartEvent = (date) => ({
    start: date.format(),
    end: moment(date).add(1, "minutes").format(),
    classNames: ["calendar-wrapper--day-start-event"],
    id: `day-start-${date.format()}`,
    allDay: false,
    editable: false,
    display: "background",
    extendedProps: {
        eventView: EVENT_VIEW.DAY_START,
    },
});

export const createTravelCalendarEvent = (event, nextEvent) => {
    const startEventCoords = getEventCoordinates(event);
    const endEventCoords = getEventCoordinates(nextEvent);
    return {
        start: event.end,
        end: nextEvent.start,
        id: `travel-${event.id}-${nextEvent.id}`,
        allDay: false,
        editable: false,
        classNames: ["calendar-wrapper--travel-event"],
        extendedProps: {
            eventView: EVENT_VIEW.TRAVEL,
            distance: 0,
            duration: 0,
            startEventId: event?.id,
            endEventId: nextEvent?.id,
            startEventCoords,
            endEventCoords,
        },
    };
};

export const isAllDayEvent = (eventDateStart, eventDateEnd) => {
    return (
        eventDateStart?.includes("T00:00:00+00:00") ||
        eventDateStart === eventDateEnd ||
        (eventDateStart && !eventDateEnd)
    );
};

const getEventCardColor = ({ optional, provider }) => {
    let classNames = [""];
    if (!provider) {
        return classNames;
    }
    const key = provider.replace('/', '-');
    console.log("keys", key);

    classNames = 'item-tourism item-' + key + (optional ? ' optional' : '');

    return classNames;
};

export const addHomeEvents = (evs, address) => {
    if (!evs || !Array.isArray(evs) || !evs.length) return [];
    if (address?.coordinates && hasLatLng(address.coordinates)) {
        const commonProps = {
            allDay: false,
            editable: false,
            classNames: ["calendar-wrapper--home-event "],
            extendedProps: {
                eventView: EVENT_VIEW.HOME,
                coordinates: address.coordinates,
                address,
            },
        };
        const start = {
            start: moment(evs[0].start).subtract(60, "minutes").format(),
            end: moment(evs[0].start).subtract(30, "minutes").format(),
            id: HOME_START_ID,
            title: "home start",
            ...commonProps,
        };
        const end = {
            start: moment(evs[evs.length - 1].end)
                .add(30, "minutes")
                .format(),
            end: moment(evs[evs.length - 1].end)
                .add(60, "minutes")
                .format(),
            id: HOME_END_ID,
            ...commonProps,
        };
        return [start, ...evs, end];
    }
    return evs;
};

const getTravels = async (slice, map) => {
    let travels = [];

    if (slice?.length === 0) {
        return slice;
    }
    if (slice?.length === 1) {
        travels.push(createTravelCalendarEvent(slice[0], slice[0]));
    }
    for (const [_i, event] of slice.entries()) {
        const nextEvent = slice[_i + 1];
        const bothEventsHaveLatLng =
            hasLatLng(getEventCoordinates(event)) &&
            hasLatLng(getEventCoordinates(nextEvent));
        if (bothEventsHaveLatLng) {
            travels.push(createTravelCalendarEvent(event, nextEvent));
        }
    }
    try {
        const direction = await getDirections(
            slice.map((e) => getLatLng(getEventCoordinates(e))),
            map
        );
        travels = travels.map((e, _i) => {
            if (_i === 0) {
                e.extendedProps.direction = direction;
            }
            e.extendedProps.distance =
                direction?.routes?.[0].legs?.[_i]?.distance;
            e.extendedProps.duration =
                direction?.routes?.[0].legs?.[_i]?.duration;
            const actualDurationSeconds = e.extendedProps.duration?.value;
            const calculatedDurationSeconds = moment(e.end).diff(
                moment(e.start),
                "seconds"
            );
            if (calculatedDurationSeconds < actualDurationSeconds) {
                e.classNames.push("lack-of-time");
            } else {
                e.start = moment(e.end)
                    .subtract(actualDurationSeconds, "seconds")
                    .format();
            }
            return e;
        });
    } catch (e) {
        console.error(e);
    }

    return [...travels, ...slice];
};

const getAccommodationShadowEventCreator = () => {
    const keys = {};
    return (originalAccommodationEvent, isStart = false) => {
        const event = cloneDeep(originalAccommodationEvent);
        event.id in keys ? keys[event.id]++ : (keys[event.id] = 0);
        event.id = `${EVENT_VIEW.ACCOMMODATION_SHADOW}-${event.id}-index-${
            keys[event.id]
        }`;
        event.extendedProps.eventView = EVENT_VIEW.ACCOMMODATION_SHADOW;
        event.classNames = ["calendar-wrapper--accommodation-shadow-event"];
        event.allDay = false;
        event.editable = false;
        event.extendedProps.isStart = isStart;
        event.extendedProps.originalEventId = originalAccommodationEvent.id;
        return event;
    };
};

const addAccommodationShadowEvents = (
    evs,
    allDayEvents,
    offerStartTime,
    offerEndTime
) => {
    const hasHomeEvents = (evs ?? []).some((e) =>
        [HOME_START_ID, HOME_END_ID].includes(e.id)
    );
    const getOfferDates = () => {
        const range = moment.range(
            offerStartTime.startOf("date"),
            offerEndTime.startOf("date")
        );
        const dates = [...range.by("day", { excludeEnd: true })].map((d) =>
            d.format()
        );
        return dates;
    };
    const allEvents = [...evs, ...allDayEvents].sort(sortEvents);
    const offerDates = getOfferDates();
    // group events by dates
    // event belongs to date if time ranges intersects
    const datesWithEvents = offerDates.reduce((acc, date) => {
        const _events = allEvents.filter((e) => {
            const eStart = e.extendedProps.isAccommodation
                ? moment(moment(e.start).format("YYYY-MM-DDTHH:mm:ss")).startOf(
                      "date"
                  )
                : moment(e.start);
            const eEnd = e.extendedProps.isAccommodation
                ? moment(moment(e.end).format("YYYY-MM-DDTHH:mm:ss")).startOf(
                      "date"
                  )
                : moment(e.end);
            const eRange = moment.range(eStart, eEnd);
            const dateRange = moment.range(
                moment(date).startOf("date"),
                moment(date).endOf("date")
            );
            return dateRange.intersect(eRange);
        });
        return {
            ...acc,
            ...(_events?.length > 0 && { [date]: _events }),
        };
    }, {});

    let prevDayAcc = null;
    const createAccommodationShadowEvent = getAccommodationShadowEventCreator();
    Object.keys(datesWithEvents).forEach((date, i, _dates) => {
        const dateEvents = datesWithEvents[date];
        const isFistDate = i === 0;
        const isLastDate = i === _dates.length - 1;
        const currentDayAcc = dateEvents.find(
            (e) => e.extendedProps.isAccommodation && !e.extendedProps.optional
        );

        const sourceStartEvent =
            !hasHomeEvents && isFistDate ? currentDayAcc : prevDayAcc;
        const sourceEndEvent =
            hasHomeEvents && isLastDate ? null : currentDayAcc;

        if (sourceStartEvent) {
            const evt = createAccommodationShadowEvent(sourceStartEvent, true);
            evt.start = moment(date).startOf("date").format();
            evt.end = moment(evt.start)
                .add(
                    DEFAULT_ACCOMMODATION_SHADOW_EVENT_DURATION_MINUTES,
                    "minutes"
                )
                .format();
            dateEvents.unshift(evt);
        }
        if (sourceEndEvent) {
            const evt = createAccommodationShadowEvent(sourceEndEvent);
            evt.end = moment(date).endOf("date").format();
            evt.start = moment(evt.end)
                .subtract(
                    DEFAULT_ACCOMMODATION_SHADOW_EVENT_DURATION_MINUTES,
                    "minutes"
                )
                .format();
            dateEvents.push(evt);
        }
        prevDayAcc = cloneDeep(currentDayAcc);
    });

    return Object.values(datesWithEvents)
        .flatMap((x) => x)
        .filter((x) => !x.allDay);
};

export const addTravelEvents = async (events) => {
    const travelSlices = [[]];
    const travelEventsWithoutCoords = [];
    let i = 0;
    events.forEach((e) => {
        const hasCoords = hasLatLng(getEventCoordinates(e));
        if (hasCoords) {
            travelSlices[i].push(e);
        } else {
            travelEventsWithoutCoords.push(e);
            travelSlices.push([]);
            i = i + 1;
        }
    });

    const travelEvents = (
        await Promise.all(travelSlices.map((s) => getTravels(s)))
    ).flatMap((x) => x);

    return [...travelEvents, ...travelEventsWithoutCoords];
};

export const adjustEvents = (events, dayStartTimes) => {
    const markTravelEventStart = (travelEvent, evts) => {
        if (!travelEvent || !evts?.length) return;
        travelEvent.classNames = travelEvent.classNames.filter(
            (cls) => cls !== "open-start"
        );
        const startTravelEvent = evts.find(
            (e) => e.id === travelEvent.extendedProps.startEventId
        );
        const travelStart = moment(travelEvent.start);
        if (moment(travelStart).isAfter(moment(startTravelEvent?.end))) {
            travelEvent.classNames.push("open-start");
        }
    };
    const adjustTravelEvent = (evt, thresholdMinutes = 15) => {
        if (!evt.originalStartTime) {
            evt.originalStartTime = evt.start;
        }
        if (!evt.originalEndTime) {
            evt.originalEndTime = evt.end;
        }
        evt.start = evt.originalStartTime;
        evt.end = evt.originalEndTime;
        const dateStartTime = getDateStartTime(evt.start, dayStartTimes);
        // cut travel event on day start time
        evt.classNames = evt.classNames.filter((cls) => cls !== "lack-of-time");
        if (dateStartTime.isAfter(moment(evt.start))) {
            evt.originalStartTime = evt.start;
            evt.start = dateStartTime.toISOString();
            evt.classNames.push("lack-of-time");
        }
        // if travel event is too short, then adjust to the threshold
        if (evt.extendedProps.duration.value < thresholdMinutes * MINUTE) {
            evt.originalStartTime = evt.start;
            evt.start = moment(evt.end)
                .subtract(thresholdMinutes, "minute")
                .toISOString();
        }
        return evt;
    };

    const adjustHomeAndItsTravelEvent = (evt, evts) => {
        const mapping = {
            ["home_start"]: {
                id: "startEventId",
            },
            ["home_end"]: { id: "endEventId" },
        };
        const obj = mapping[evt.id];
        const homeTravelEvt = evts?.find(
            (e) => evt.id === e.extendedProps[obj.id]
        );

        if (homeTravelEvt) {
            const duration = homeTravelEvt.extendedProps.duration.value;
            homeTravelEvt.classNames = homeTravelEvt.classNames.filter(
                (cls) => cls !== "lack-of-time"
            );
            if (evt.id === "home_start") {
                evt.end = moment(homeTravelEvt.end)
                    .subtract(duration, "seconds")
                    .format();
                evt.start = moment(evt.end).subtract(30, "minutes").format();
                homeTravelEvt.start = evt.end;
                adjustTravelEvent(homeTravelEvt);
            }
            if (evt.id === "home_end") {
                adjustTravelEvent(homeTravelEvt);
                evt.start = moment(homeTravelEvt.start)
                    .add(duration, "seconds")
                    .format();
                evt.end = moment(evt.start).add(30, "minutes").format();
                homeTravelEvt.end = evt.start;
            }
            markTravelEventStart(homeTravelEvt, evts);
        }
        return evt;
    };

    const adjustAccommodationShadowEvent = (evt, evts) => {
        const isStart = evt.extendedProps.isStart;
        const travelEvent = evts.find(
            (e) =>
                e.extendedProps[isStart ? "startEventId" : "endEventId"] ===
                evt.id
        );
        if (isStart) {
            if (travelEvent) {
                adjustTravelEvent(travelEvent);
                evt.end = moment(travelEvent.start).format();
                evt.start = moment(evt.end)
                    .subtract(
                        DEFAULT_ACCOMMODATION_SHADOW_EVENT_DURATION_MINUTES,
                        "minutes"
                    )
                    .format();
            } else {
                const dateStartTime = getDateStartTime(
                    evt.start,
                    dayStartTimes
                );
                evt.end = moment(dateStartTime).format();
                evt.start = moment(evt.end)
                    .subtract(
                        DEFAULT_ACCOMMODATION_SHADOW_EVENT_DURATION_MINUTES,
                        "minutes"
                    )
                    .format();
            }
        } else {
            if (travelEvent) {
                const travelStartEvent = evts.find(
                    (e) => e.id === travelEvent.extendedProps.startEventId
                );
                if (travelStartEvent) {
                    evt.start = moment(travelStartEvent.end)
                        .add(
                            travelEvent.extendedProps.duration.value <
                                (DEFAULT_ACCOMMODATION_SHADOW_EVENT_DURATION_MINUTES /
                                    2) *
                                    MINUTE
                                ? (DEFAULT_ACCOMMODATION_SHADOW_EVENT_DURATION_MINUTES /
                                      2) *
                                      MINUTE
                                : travelEvent.extendedProps.duration.value,
                            "seconds"
                        )
                        .format();
                    evt.end = moment(evt.start)
                        .add(
                            DEFAULT_ACCOMMODATION_SHADOW_EVENT_DURATION_MINUTES,
                            "minutes"
                        )
                        .format();
                    travelEvent.start = travelStartEvent.end;
                    travelEvent.end = evt.start;
                    delete travelEvent.originalStartTime;
                    delete travelEvent.originalEndTime;
                    adjustTravelEvent(travelEvent);
                }
            } else {
                const dateStartTime = getDateStartTime(
                    evt.start,
                    dayStartTimes
                );
                evt.start = moment(dateStartTime).set("hour", 20).format();
                evt.end = moment(evt.start)
                    .add(
                        DEFAULT_ACCOMMODATION_SHADOW_EVENT_DURATION_MINUTES,
                        "minutes"
                    )
                    .format();
            }
        }
        markTravelEventStart(travelEvent, evts);
        return evt;
    };
    return events.map((evt, _, evts) => {
        switch (evt.extendedProps.eventView) {
            case EVENT_VIEW.TRAVEL: {
                const _event = adjustTravelEvent(evt);
                markTravelEventStart(_event, evts);
                return _event;
            }
            case EVENT_VIEW.HOME: {
                return adjustHomeAndItsTravelEvent(evt, evts);
            }
            case EVENT_VIEW.ACCOMMODATION_SHADOW: {
                return adjustAccommodationShadowEvent(evt, evts);
            }
            default: {
                return evt;
            }
        }
    });
};

const prepareEventsForCalendar = async ({
    calendarEvents,
    address,
    offerStartTime,
    offerEndTime,
}) => {
    const { allDayEvents, commonEvents } = calendarEvents.reduce(
        (acc, evt) => {
            const event = createCalendarEvent(evt);
            event.allDay
                ? acc.allDayEvents.push(event)
                : acc.commonEvents.push(event);
            return acc;
        },
        {
            allDayEvents: [],
            commonEvents: [],
        }
    );
    let events = addHomeEvents(commonEvents, address);
    events = addAccommodationShadowEvents(
        events,
        allDayEvents,
        offerStartTime,
        offerEndTime
    );
    events = await addTravelEvents(events);
    events = [...allDayEvents, ...events].sort(sortEvents);
    return events;
};

export const removeShortDistanceTravels = (events, limitMeters = 30) =>
    events.filter(
        (e) =>
            !(
                e.extendedProps.eventView === EVENT_VIEW.TRAVEL &&
                e.extendedProps.distance.value < limitMeters
            )
    );

export default prepareEventsForCalendar;
