export const getOfferSettingUrl = (id) => `order-offer/offer-settings/${id}`;

export const getOrderItemUrl = (id) =>
    `order/order-item?embeds[]=product&sort[0][0]=sort&sort[0][1]=ASC&conditions[order]=${id}`;

export const getDeleteOrderItemUrl = (id) => `order/order-item/${id}`;

export const getAddOrderItemUrl = (id) => `order/order-item-add/trigger/${id}`;

export const getOrderItemOptionalUrl = (id) =>
    `order-offer/set-order-item-optional/${id}`;

export const getOrderItemMoveUrl = (id) => `order-item/move/${id}`;
