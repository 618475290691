import React, { useState, useEffect, useContext } from "react";
import { Col, Grid, Row } from "rsuite";
import { OfferCalendarWrapper } from "./offer-calendar-wrapper";
import { OfferSearchForm } from "../components/offer-search-form";
import { OfferPreview } from "../components/offer-preview";
import { OfferResultsCard } from "../components/offer-results-card";
import { CALENDAR_CONTAINER_WIDTH, VIEWS } from "../constants/index";
import { SIOGoogleMap } from "../../../map/google-map/sio-google-map";
import { GMapContext, withGMapOptions } from "../helper/gmap-context";
import "./offer-search.less";
import { OrderContext } from "../helper/order-context";
import { OfferPricing } from "../components/offer-pricing";
import { useCallback } from "react";
import { useMemo } from "react";

const GRID_COLS = 24;
const SIZING = {
  [VIEWS.DAY]: { calendar: 7 },
  [VIEWS.WEEK]: { calendar: 12, search: 12, map: 12 },
  [VIEWS.LONG_WEEK]: { calendar: 13 },
};

const GMap = withGMapOptions(SIOGoogleMap);

const GoogleMap = React.memo(({ markers, directions }) => {
  const { setMap, map } = useContext(GMapContext);
  useEffect(() => {
    const displays = [];
    directions.forEach((d) => {
      const display = new google.maps.DirectionsRenderer({
        map,
      });
      display.setDirections(d);
      displays.push(display);
    });
    return () => displays.forEach((d) => d.setMap(null));
  }, [map, directions]);
  const onReady = useCallback((_, gmap) => {
    setMap(gmap);
  });
  return (
    <GMap
      zoom={12}
      height="100%"
      position="static"
      hasInfoView
      markers={markers}
      directions={directions}
      onReady={onReady}
    />
  );
});

export const OfferSearch = ({ items, firstDate, participants }) => {
  const [calendarView, setCalendarView] = useState(VIEWS.WEEK);
  const [selectedSearchItem, setSelectedSearchItem] = useState(null);
  const [searchItems, setSearchItems] = useState([]);
  const { selectedDate } = useContext(OrderContext);
  const [directions, setDirections] = useState([]);

  const clearSelectedItem = () => setSelectedSearchItem(null);

  const initialDate = useMemo(() => {
    const getInitialDate = (events) => {
      const sortedEvents = [...events].sort(
        (previous, next) =>
          new Date(previous.fromDate).getTime() -
          new Date(next.fromDate).getTime()
      );
      const earliestEvent = sortedEvents[0];
      const earliestEventStartDate = earliestEvent.fromDate;
      return moment(earliestEventStartDate);
    };
    return items.length > 0 ? getInitialDate(items) : firstDate;
  }, [items, firstDate]);

  const markers = useMemo(
    () =>
      searchItems.map((item) => ({
        position: {
          lat: item.coordinates?.lat,
          lng: item.coordinates?.lng,
        },
        onMarkerSelect: () => {
          setSelectedSearchItem(item);
        },
        component: <OfferResultsCard item={item} />,
      })),
    [searchItems]
  );

  const changeCalendarView = useCallback(
    (key) => setCalendarView(VIEWS[key]),
    []
  );

  return (
    <Grid fluid className="search-view-calendar-container">
      <Row className="search-view-calendar-section">
        <Col xs={GRID_COLS} sm={SIZING[calendarView].calendar}>
          {initialDate && (
            <OfferCalendarWrapper
              events={items}
              activeView={calendarView}
              initialDate={initialDate}
              selectedDate={selectedDate}
              timeToScroll="08:00:00"
              setDirections={setDirections}
              changeCalendarView={changeCalendarView}
            />
          )}
        </Col>
        <Col
          xs={24}
          sm={GRID_COLS - SIZING[calendarView].calendar}
          className="search-view-right-section "
        >
          <Row className="search-view-full-height">
            <Col
              xs={GRID_COLS}
              sm={SIZING[calendarView]?.search ?? GRID_COLS / 2}
              className="search-view-full-height"
            >
              <OfferSearchForm
                firstDate={selectedDate}
                participants={participants}
                selectedSearchItem={selectedSearchItem}
                onResultSelection={setSelectedSearchItem}
                onResultSearch={setSearchItems}
                clearSelectedItem={clearSelectedItem}
              />
            </Col>
            <Col
              xs={GRID_COLS}
              sm={SIZING[calendarView]?.map ?? GRID_COLS / 2}
              className="search-view-full-height"
            >
              {selectedSearchItem ? (
                <OfferPreview
                  participants={participants}
                  firstDate={firstDate}
                  selectedSearchItem={selectedSearchItem}
                  clearSelectedItem={clearSelectedItem}
                  className="search-view-full-height"
                />
              ) : (
                <>
                  <Row className="offer-map-container">
                    <GoogleMap markers={markers} directions={directions} />
                  </Row>
                  <Row>
                    <OfferPricing className="offer-price" />
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  );
};
