import _ from "lodash";
import moment from "../../../../node_modules/moment-timezone/index";

const currencyMapping = {
    EUR: "€",
};

export const SECOND = 1;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const DEFAULT_DAY_START_TIME = "08:00";
export const getCustomerAddress = (order) =>
    order?.customer?.address || order?.invoiceAddress;
export const getDateStartTime = (date, dayStartTimes = {}) => {
    const _date = moment(date).format("YYYY-MM-DD");

    const dateStartTime = moment(
        `${_date} ${dayStartTimes[_date] ?? DEFAULT_DAY_START_TIME}`
    );
    return moment(dateStartTime);
};

export const formatPriceWithCommas = (price, currency) => {
    if (typeof price == "number") {
        price = price / 100; // transform from cent to euro
        const formatedPrice = price.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        const sign = _.get(currencyMapping, currency, "");
        return `${formatedPrice} ${sign}`;
    }
    return null;
};

export const getOfferModelPayload = (offerModel) => {
    const {
        participantsForm,
        firstDate,
        lastDate,
        optionDate,
        offerCancellationDate,
        offerDoubleRooms,
        offerSingleRooms,
        overwriteOfferCancellationDate,
        overwriteOptionDate,
    } = offerModel;
    return {
        b2bParticipants: participantsForm,
        offerStartDate: firstDate,
        offerEndDate: lastDate,
        optionDate,
        offerCancellationDate,
        offerDoubleRooms,
        offerSingleRooms,
        overwriteOfferCancellationDate,
        overwriteOptionDate,
    };
};

export const getStartingTimeDict = (staringTimes = []) =>
    staringTimes.reduce(
        (acc, i) => ({
            ...acc,
            [i.date.split("T")[0]]: `${i.time.slice(
                0,
                i.time.length / 2
            )}:${i.time.slice(i.time.length / 2, i.time.length)}`,
        }),
        {}
    );
