import { getFirstAndLastDate } from "./date-util";
import moment from "moment-timezone";

export const getConfigOptions = (order, orderItems, offerModel) => {
    const DEFAULT_ORDER_ROOMS = 0;
    const DEFAULT_PARTICIPANTS = 0;

    let firstDate = order.offerStartDate ? moment(order.offerStartDate) : null;
    let lastDate = order.offerEndDate ? moment(order.offerEndDate) : null;

    for (const item of orderItems) {
        ({ firstDate, lastDate } = getFirstAndLastDate(
            item,
            firstDate,
            lastDate
        ));
    }

    if (!firstDate && !lastDate) {
        firstDate = moment().startOf("day");
        lastDate = firstDate.clone().add(14, "days");
    }

    if (lastDate.diff(firstDate, "days") < 14) {
        lastDate = firstDate.clone().add(14, "days");
    }

    const duration = lastDate.diff(firstDate, "day") + 1;
    const newCancellationDate =
        moment(order?.offerCancellationDate) || moment();
    const offerCancellationTime = firstDate.diff(newCancellationDate, "day");

    return {
        ...offerModel,
        firstDate: moment(firstDate),
        lastDate: moment(lastDate),
        participantsForm: order.b2bParticipants || DEFAULT_PARTICIPANTS,
        optionDate: moment(order?.optionDate),
        offerCancellationDate: newCancellationDate,
        offerDoubleRooms: order.offerDoubleRooms || DEFAULT_ORDER_ROOMS,
        offerSingleRooms: order.offerSingleRooms || DEFAULT_ORDER_ROOMS,
        duration: duration,
        offerCancellationTime: offerCancellationTime,
        overwriteOfferCancellationDate: order.overwriteOfferCancellationDate,
        overwriteOptionDate: order.overwriteOptionDate,
    };
};
