import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import { Panel } from "rsuite";
import { OrderContext } from "../helper/order-context";
import { OfferCalendar } from "../components/offer-calendar";
import { OfferCalendarDialog } from "../components/offer-calendar-dialog";
import "./offer-calendar-wrapper.less";
import { EVENT_VIEW } from "../constants/index";
import { GMapContext } from "../helper/gmap-context";
import prepareEventsForCalendar, {
    adjustEvents,
    removeShortDistanceTravels,
} from "../helper/events-utilities";
import { getCustomerAddress } from "../helper/utility";

export const OfferCalendarWrapper = ({
    events: calendarEvents = [],
    activeView,
    initialDate,
    timeToScroll = null,
    selectedDate,
    setDirections,
    changeCalendarView,
}) => {
    const { order } = useContext(OrderContext);
    const { map } = useContext(GMapContext);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState({});
    const [open, setOpen] = useState(false);

    const {
        onDeleteEvent,
        onDateChange,
        onOptionalChange,
        onEventDrag,
        dayStartTimes,
        offerStartTime,
        offerEndTime,
    } = useContext(OrderContext);

    useEffect(() => {
        setDirections?.(
            events
                .filter((e) => e.extendedProps.eventView === EVENT_VIEW.TRAVEL)
                .map((e) => e.extendedProps.direction)
                .filter(Boolean)
        );
    }, [events, setDirections]);

    useEffect(() => {
        if (!map || !offerStartTime || !offerEndTime) return;
        const populateEvents = async () => {
            const address = getCustomerAddress(order);
            const events = await prepareEventsForCalendar({
                calendarEvents,
                address,
                offerStartTime,
                offerEndTime,
            });
            setEvents(removeShortDistanceTravels(events));
        };
        populateEvents();
    }, [calendarEvents, order, map, offerStartTime, offerEndTime]);

    const adjustedEvents = useMemo(() => adjustEvents(events, dayStartTimes), [
        events,
        dayStartTimes,
    ]);

    const handleClose = () => setOpen(false);

    const handleSuccess = () => {
        onOptionalChange(selectedEvent.optional, selectedEvent.id);
        setOpen(false);
    };

    const eventClick = (event) => {
        setOpen(true);
        setSelectedEvent(event.event.extendedProps);
    };

    const eventDrop = ({ event, revert }) => {
        const isAccommodation = event.extendedProps.isAccommodation;
        if (isAccommodation && !event.end) {
            revert();
            return;
        }
        onEventDrag(event);
    };

    const eventResize = ({ event, revert }) => {
        const isAccommodation = event.extendedProps.isAccommodation;
        isAccommodation ? onEventDrag(event) : revert();
    };

    const checkboxChangeHandler = (value, checked) => {
        setSelectedEvent((previousValue) => ({
            ...previousValue,
            optional: checked,
        }));
    };

    // next piece of code is for the calendar to be able to scroll to the required time slot (8am in this case)
    // FullCalendar's scrollTime and scrollToTime are not working properly (due to 'auto' value of contentHeight property) ,
    // so I had to create this workaround
    const scrollRef = useRef();
    useEffect(() => {
        const scrollDom = ReactDOM.findDOMNode(scrollRef?.current);
        if (!timeToScroll) {
            scrollDom?.scrollTo(0, 0);
            return;
        }
        const scrollToTime = (time) => {
            const timeRef = scrollDom?.querySelector(
                `.fc-timegrid-slot.fc-timegrid-slot-label[data-time="${time}"]`
            );
            timeRef?.scrollIntoView(true);
            const headerHeight = scrollDom
                ?.querySelector(".fc-col-header")
                ?.getBoundingClientRect().height;
            scrollDom?.style.setProperty(
                "--all-day--sticky-top",
                `${headerHeight}px`
            );
            const allDayContainerHeight = scrollDom
                ?.querySelector(".fc-scrollgrid-section-body:first-child")
                .getBoundingClientRect().height;
            scrollDom?.scrollTo(
                0,
                scrollDom?.scrollTop - headerHeight - allDayContainerHeight
            );
        };
        // as we're going to work with DOM elements, we need to wait for the DOM to be rendered
        const requestId = window?.requestAnimationFrame(() =>
            scrollToTime(timeToScroll)
        );
        return () => window?.cancelAnimationFrame(requestId);
    }, [events, activeView, timeToScroll]);
    return (
        <Panel bordered className={"calendar-wrapper"} ref={scrollRef}>
            <OfferCalendar
                events={adjustedEvents}
                eventClick={eventClick}
                eventDrop={eventDrop}
                eventResize={eventResize}
                initialDate={initialDate}
                activeView={activeView}
                onDateChange={onDateChange}
                onDeleteEvent={onDeleteEvent}
                eventStartEditable={true}
                eventDurationEditable={true}
                selectedDate={selectedDate}
                changeCalendarView={changeCalendarView}
            />
            <OfferCalendarDialog
                open={open}
                handleSuccess={handleSuccess}
                handleClose={handleClose}
                selectedEvent={selectedEvent}
                checkboxChangeHandler={checkboxChangeHandler}
            />
        </Panel>
    );
};
